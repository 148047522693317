import { TextField, TextFieldProps } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"

export type ControlledTextFieldProps = Partial<TextFieldProps> & {
  name: string
}

const ControlledTextField = ({
  name,
  label,
  ...props
}: ControlledTextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <TextField
          fullWidth
          onChange={(event) => onChange(event.target.value || null)}
          value={value || ""}
          label={label}
          error={!!errors[name]}
          helperText={errors[name]?.message?.toString()}
          margin="dense"
          size="small"
          {...props}
        />
      )}
    />
  )
}
export default ControlledTextField
