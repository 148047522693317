import { useMutation, useQuery, useQueryClient } from "react-query"
import { getUsersMe, login, logout } from "../api"
import { User } from "../types/user"

interface LoginData {
  username: string
  password: string
}

export const useLoginMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: LoginData) => login(data),
    onSuccess: () => {
      queryClient.invalidateQueries("usersMe")
    },
  })
}

export const useLogoutMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: logout,
    onSuccess: () => queryClient.invalidateQueries("usersMe"),
  })
}

export const useUsersMeQuery = () => {
  return useQuery<User, Error>({
    queryKey: "usersMe",
    queryFn: getUsersMe,
  })
}
