import { Box } from "@mui/material"


const DashboardPage = () => {
  return (
    <Box display="flex" sx={{ p: 4, gap: 3 }}>
    </Box>
  )
}
export default DashboardPage
