import { DatePicker } from "@mui/x-date-pickers"
import { Controller, useFormContext } from "react-hook-form"
import { toDayjs } from "../../../../utils/dates"

interface ControlledDatePickerProps {
  name: string
  label: string
}

const ControlledDatePicker = ({ name, label }: ControlledDatePickerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <DatePicker
          views={["year", "month", "day"]}
          onChange={onChange}
          value={toDayjs(value) || ""}
          label={label}
          openTo="year"
          slotProps={{
            textField: {
              fullWidth: true,
              size: "small",
              error: !!errors[name],
              helperText: errors[name]?.message?.toString(),
              margin: "dense",
              defaultValue: value,
            },
          }}
        />
      )}
    />
  )
}
export default ControlledDatePicker
