import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material"
import LoadingBackdrop from "../../../common/components/LoadingBackdrop"
import {
  useCaregiverReferenceCreateMutation,
  useCaregiverReferenceDeleteMutation,
  useCaregiverReferencesQuery,
} from "../hooks/caregiver"
import { FormProvider, useForm } from "react-hook-form"
import ControlledTextField from "../../../common/components/Inputs/ControlledTextField"
import { useState } from "react"
import {
  CaregiverReference,
  CaregiverReferencePayload,
} from "../types/caregiver"
import ControlledFileField from "../../../common/components/Inputs/ControlledFileField"
import ControlledSelect from "../../../common/components/Inputs/ControlledSelect"
import { timeFromNowAgo } from "../../../utils/dates"
import DeleteIcon from "@mui/icons-material/Delete"
import * as yup from "yup"

import { yupResolver } from "@hookform/resolvers/yup"

interface CaregiverReferencesProps {
  caregiverId: number
}

interface CreateDialogProps {
  caregiverId: number
  isOpened: boolean
  setIsOpened: (opened: boolean) => void
}

const referenceTypes = [
  { label: "Rekomendacja", value: "RECOMMENDATION" },
  { label: "Wykształcenie medyczne", value: "MEDICAL" },
  { label: "Zdjęcie z podpopiecznymi", value: "PHOTO" },
]

const validationSchema = yup.object({
  name: yup.string().min(3).required("Podaj nazwę pliku."),
  referenceTypes: yup.string().oneOf(["MEDICAL", "RECOMMENDATION", "PHOTO"]),
  file: yup.mixed().required("Musisz wybrać plik."),
})

const CreateDialog = ({
  caregiverId,
  isOpened,
  setIsOpened,
}: CreateDialogProps) => {
  const methods = useForm<CaregiverReferencePayload>({
    defaultValues: { referenceType: "RECOMMENDATION" },
    resolver: yupResolver(validationSchema),
  })
  const { mutate } = useCaregiverReferenceCreateMutation()

  const submit = (data: CaregiverReferencePayload) => {
    mutate({ caregiverId, data })
    methods.reset()
  }

  return (
    <Dialog open={isOpened}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((data) => submit(data))}>
          <DialogTitle id="edit-apartment">Nowy załącznik</DialogTitle>
          <DialogContent>
            <DialogContentText>Podaj nazwę oraz typ pliku.</DialogContentText>
            <ControlledTextField name="name" label="Nazwa pliku" />
            <ControlledSelect
              name="referenceType"
              label="Rodzaj"
              options={referenceTypes}
            />
            <ControlledFileField name="file" />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsOpened(false)}
              variant="contained"
              color="error"
            >
              Anuluj
            </Button>
            <Button
              type="submit"
              disabled={!methods.formState.isValid}
              onClick={() => setIsOpened(false)}
              variant="contained"
            >
              Dodaj
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

const ConfirmDialog = ({
  open,
  onCancel,
  onConfirm,
}: {
  open: boolean
  onCancel: () => void
  onConfirm: () => void
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Potwierdź usunięcie pliku</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Czy na pewno chcesz usunąć wybrany plik?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel}>
          Anuluj
        </Button>
        <Button variant="contained" color="error" onClick={onConfirm} autoFocus>
          Usuń
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ReferenceTableRow = ({
  caregiverId,
  reference,
}: {
  caregiverId: number
  reference: CaregiverReference
}) => {
  const { mutate } = useCaregiverReferenceDeleteMutation()
  const [isOpened, setIsOpened] = useState<boolean>(false)

  return (
    <TableRow
      key={reference.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
        <Link target="_blank" href={reference.filePath}>
          {reference.name}
        </Link>
      </TableCell>
      <TableCell>
        {
          referenceTypes.find((ref) => ref.value === reference.referenceType)
            ?.label
        }
      </TableCell>
      <TableCell>{timeFromNowAgo(reference.createdAt)}</TableCell>
      <TableCell align="right">
        <ConfirmDialog
          open={isOpened}
          onCancel={() => setIsOpened(false)}
          onConfirm={() => mutate({ caregiverId, referenceId: reference.id })}
        />
        <IconButton color="error" onClick={() => setIsOpened(true)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export const CaregiverReferences = ({
  caregiverId,
}: CaregiverReferencesProps) => {
  const { data, isLoading, isError, isIdle } =
    useCaregiverReferencesQuery(caregiverId)
  const [isOpened, setIsOpened] = useState<boolean>(false)

  if (isLoading) return <LoadingBackdrop />

  if (isError || isIdle) return <>Error...</>

  return (
    <>
      {data.length > 0 ? (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nazwa pliku</TableCell>
              <TableCell>Rodzaj</TableCell>
              <TableCell>Dodano</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((reference) => (
              <ReferenceTableRow
                key={reference.id}
                caregiverId={caregiverId}
                reference={reference}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        "Nie dodano żadnych załączników."
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          sx={{ marginTop: 2 }}
          disableElevation
          variant="contained"
          onClick={() => setIsOpened(true)}
          color="primary"
        >
          Dodaj
        </Button>
      </Box>
      <CreateDialog
        caregiverId={caregiverId}
        isOpened={isOpened}
        setIsOpened={setIsOpened}
      />
    </>
  )
}
