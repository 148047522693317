import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form"
import {
  useCaregiverUpdateMutation,
  useCaregiverUpdatePhotoMutation,
} from "../hooks/caregiver"
import { CaregiverDetail, CaregiverUpdate } from "../types/caregiver"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import InputAdornment from "@mui/material/InputAdornment"
import { useRef, useState } from "react"
import { formatToAPI } from "../../../utils/dates"
import ControlledTextField from "../../../common/components/Inputs/ControlledTextField"
import ControlledDatePicker from "../../../common/components/Inputs/ControlledDatePicker"
import ControlledSelect from "../../../common/components/Inputs/ControlledSelect"
import { genders } from "./CaregiverExperiences"
import ControlledCheckbox from "../../../common/components/Inputs/ControlledCheckbox"
import avatar from "../../../assets/avatar.svg"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle"
import ReactCrop, { PixelCrop, type Crop } from "react-image-crop"
import 'react-image-crop/dist/ReactCrop.css'
import { canvasPreview } from "../../../utils/canvasPreview"


const UploadPhotoModal = ({ caregiver, open, setOpen }: { caregiver: CaregiverDetail, open: boolean, setOpen: (open: boolean) => void }) => {
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const imgRef = useRef<HTMLImageElement>(null);
  const { mutate } = useCaregiverUpdatePhotoMutation()

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onSave = async () => {
    if (imgRef.current && completedCrop) {
      const offscreen = new OffscreenCanvas(completedCrop.width, completedCrop.height)
      await canvasPreview(
        imgRef.current,
        offscreen,
        completedCrop
      )

      const blob = await offscreen.convertToBlob({
        type: 'image/png',
      })

      const blobSizeMB = blob.size / (1024 * 1024);

      if (blobSizeMB < 2) {
        mutate({ caregiverId: caregiver.id, data: { file: new File([blob], "profile.png") } })
        handleClose()
      } else {
        setErrorMessage("Rozmiar zdjęcia jest zbyt duży.");
      }

    }
  }

  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
    setImgSrc("");
  }

  return (
    <>
      <Typography sx={{ marginTop: 3, marginBottom: 2 }} variant="h5">
        Zdjęcie profilowe
      </Typography>
      <Typography variant="subtitle2">
        Maksymalnie 2MB (.jpg,.jpeg,.png,itd.)
      </Typography>
      <Box sx={{ width: '300px' }}>
        <img width="300px" src={caregiver.photoPath ? caregiver.photoPath : avatar} />

      </Box>
      <Button variant="contained" onClick={() => setOpen(true)}>
        Wybierz zdjęcie
      </Button>
      <Dialog open={open}>
        <DialogTitle id="edit-apartment">Nowe zdjęcie</DialogTitle>
        <DialogContent>
          {!imgSrc && <Button component="label" variant="contained">
            Wybierz zdjęcie
            <input
              type="file"
              hidden
              accept=".jpg,.jpeg,.png,.gif"
              onChange={onSelectFile}
            />
          </Button>}
          {/* <DialogContentText>Wybierz i przytnij zdjęcie.</DialogContentText> */}
          {imgSrc &&
            <ReactCrop aspect={19 / 22} crop={crop} onChange={c => setCrop(c)} onComplete={(c) => setCompletedCrop(c)}>
              <img src={imgSrc} ref={imgRef} />
            </ReactCrop>}
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleClose}
          >
            Anuluj
          </Button>
          {imgSrc && completedCrop && <Button variant="contained" disabled={!!errorMessage} onClick={onSave}>Zapisz</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}

export const nationalities = [
  { label: "Polska", value: "PL" },
  { label: "Ukraina", value: "UA" },
  { label: "Rumunia", value: "RO" },
  { label: "Niemcy", value: "DE" },
  { label: "Białoruś", value: "BY" },
  { label: "Mołdawia", value: "MD" },
  { label: "Grecja", value: "GR" },
  { label: "Włochy", value: "IT" },
  { label: "Szwajcaria", value: "CH" },
  { label: "Rosja", value: "RU" },
]

export const languages = [
  { label: "Polski", value: "PL" },
  { label: "Angielski", value: "GB" },
  { label: "Ukraiński", value: "UA" },
  { label: "Rumuński", value: "RO" },
  { label: "Białoruski", value: "BY" },
  { label: "Mołdawski", value: "MD" },
  { label: "Grecki", value: "GR" },
  { label: "Włoski", value: "IT" },
  { label: "Rosyjski", value: "RU" },
]

const educations = [
  { label: "Podstawowe", value: "PRIMARY" },
  { label: "Średnie", value: "SECONDARY" },
  { label: "Wyższe", value: "HIGHER" },
  { label: "Zawodowe", value: "VOCATIONAL" },
]

export const languageLevels = [
  { label: "Płynny", value: "FLUENT" },
  { label: "Tworzenie zdań", value: "SENTENCES" },
  { label: "Duże słownictwo", value: "BIG_VOCABULARY" },
  { label: "Małe słownictwo", value: "SMALL_VOCABULARY" },
  { label: "Niewiele słów", value: "FEW_WORDS" },
  { label: "Brak znajomości", value: "NO_LANGUAGE" },
]

const languageLearningSources = [
  { label: "Szkoła", value: "SCHOOL" },
  { label: "Telewizja", value: "TELEVISION" },
  { label: "Kurs w Polsce", value: "COURSE_IN_POLAND" },
  { label: "Kurs w Niemczech", value: "COURSE_IN_COUNTRY" },
  { label: "Samouk", value: "SELF_LEARNT" },
  { label: "Praca", value: "WORK" },
]

const LanguageItem = ({
  index,
  remove,
}: {
  index: number
  remove: (index: number) => void
}) => {
  return (
    <>
      <Grid item sm={5}>
        <ControlledSelect
          name={`languages.${index}.language`}
          label="Język"
          options={languages}
        />
      </Grid>
      <Grid item sm={5}>
        <ControlledSelect
          name={`languages.${index}.languageLevel`}
          label="Poziom języka"
          options={languageLevels}
        />
      </Grid>
      <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
        <IconButton color="error" onClick={() => remove(index)}>
          <RemoveCircleIcon />
        </IconButton>
      </Grid>
    </>
  )
}

interface Option {
  label: string
  value: string
}

const CaregiverPersonalInfo = () => {
  return (
    <>
      <Typography variant="h5">Dane Osobowe</Typography>
      <Grid container columnSpacing={1}>
        <Grid item sm={12} md={6}>
          <ControlledTextField disabled name="firstName" label="Imię" />
        </Grid>
        <Grid item sm={12} md={6}>
          <ControlledTextField disabled name="lastName" label="Nazwisko" />
        </Grid>

        <Grid item sm={12} lg={3}>
          <ControlledDatePicker name="dateOfBirth" label="Data urodzenia" />
        </Grid>
        <Grid item sm={12} lg={3}>
          <ControlledSelect name="gender" label="Płeć" options={genders} />
        </Grid>
        <Grid item sm={12} lg={3}>
          <ControlledTextField
            name="height"
            label="Wzrost"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">cm</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item sm={12} lg={3}>
          <ControlledTextField
            name="weight"
            label="Waga"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">kg</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <ControlledTextField name="identificationNumber" label="PESEL" />
        </Grid>
        <Grid item sm={12} lg={6}>
          <ControlledSelect
            name="nationality"
            label="Kraj"
            options={nationalities}
          />
        </Grid>
      </Grid>
    </>
  )
}

const CaregiverAdditionalInfo = ({
  availableJobs,
  availableHobbies,
}: {
  availableJobs: Option[]
  availableHobbies: Option[]
}) => {
  return (
    <>
      <Typography variant="h5">Dodatkowe informacje</Typography>
      <Grid container columnSpacing={1}>
        <Grid item sm={12}>
          <ControlledSelect
            name="education"
            label="Wykształcenie"
            options={educations}
          />
        </Grid>
        <Grid item sm={12} lg={9}>
          <ControlledSelect
            name="jobId"
            label="Zawód"
            options={availableJobs}
          />
        </Grid>
        <Grid item sm={12} lg={3}>
          <ControlledTextField
            name="seniority"
            label="Staż pracy"
            type="number"
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <ControlledDatePicker
            name="availabilityStartDate"
            label="Dostępność od"
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <ControlledDatePicker
            name="availabilityEndDate"
            label="Dostępność do"
          />
        </Grid>
        <Grid item sm={12}>
          <ControlledSelect
            multiple
            name="hobbies"
            label="Hobby i zainteresowania"
            options={availableHobbies}
          />
        </Grid>
        <Grid item sm={12}>
          <ControlledCheckbox name="hasDrivingLicense" label="Prawo jazdy" />
          <ControlledCheckbox name="isSmoker" label="Osoba paląca" />
        </Grid>
      </Grid>
    </>
  )
}

const CaregiverLanguages = () => {
  const { control } = useFormContext()
  const { append, remove, fields } = useFieldArray({
    control,
    name: "languages",
  })

  return (
    <>
      <Typography variant="h5" sx={{ marginTop: 3 }}>
        Języki
      </Typography>
      <Grid container columnSpacing={1}>
        <Grid item sm={12} lg={6}>
          <ControlledSelect
            name="germanLevel"
            label="Poziom niemieckiego"
            options={languageLevels}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <ControlledSelect
            name="germanLearningSource"
            label="Miejsce nauki niemieckiego"
            options={languageLearningSources}
          />
        </Grid>

        {fields.map((_, index) => {
          return <LanguageItem key={index} index={index} remove={remove} />
        })}
        <IconButton
          color="success"
          sx={{ marginLeft: 1, marginTop: 2 }}
          onClick={() => append({ language: "PL", languageLevel: "FLUENT" })}
        >
          <AddCircleIcon />
        </IconButton>
      </Grid>
    </>
  )
}

const CaregiverContactInfo = () => {
  return (
    <>
      <Typography variant="h5" sx={{ marginTop: 3 }}>
        Dane Kontakowe
      </Typography>
      <Grid container columnSpacing={1}>
        <Grid item sm={12} md={4}>
          <ControlledTextField name="zipCode" label="Kod pocztowy" />
        </Grid>
        <Grid item sm={12} md={8}>
          <ControlledTextField name="city" label="Miejscowość" />
        </Grid>
        <Grid item sm={12} md={6}>
          <ControlledTextField name="street" label="Ulica" />
        </Grid>
        <Grid item sm={12} md={3}>
          <ControlledTextField
            name="streetNumber"
            label="Numer domu"
            type="number"
          />
        </Grid>
        <Grid item sm={12} md={3}>
          <ControlledTextField
            name="apartamentNumber"
            label="Numer lokalu"
            type="number"
          />
        </Grid>
        <Grid item sm={12}>
          <ControlledTextField name="email" label="Email" />
        </Grid>
        <Grid item sm={12} lg={6}>
          <ControlledTextField name="phoneNumber" label="Telefon komórkowy" />
        </Grid>
        <Grid item sm={12} lg={6}>
          <ControlledTextField
            name="contactPhoneNumber"
            label="Tel. osoby kontakowej"
          />
        </Grid>
      </Grid>
    </>
  )
}

const CaregiverPhoto = ({ caregiver }: { caregiver: CaregiverDetail }) => {
  return (
    <>
      <Typography sx={{ marginTop: 3, marginBottom: 2 }} variant="h5">
        Zdjęcie profilowe
      </Typography>
      <Typography variant="subtitle2">
        Maksymalnie 2MB (.jpg,.jpeg,.png,itd.)
      </Typography>
    </>
  )
}

export const CaregiverData = ({
  caregiver,
}: {
  caregiver: CaregiverDetail
}) => {
  const availableHobbies = caregiver.hobbies.map((hobby) => {
    return { label: hobby.name, value: hobby.id }
  })
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const methods = useForm<CaregiverUpdate>({
    defaultValues: {
      ...caregiver,
      hobbies: caregiver.hobbies
        .filter((hobby) => hobby.selected)
        .map((hobby) => hobby.id),
      jobId: caregiver.jobId ? caregiver.jobId : "",
    },
  })
  const { mutate } = useCaregiverUpdateMutation()

  const transformData = ({
    dateOfBirth,
    availabilityStartDate,
    availabilityEndDate,
    jobId,
    ...data
  }: CaregiverUpdate): CaregiverUpdate => {
    return {
      ...data,
      dateOfBirth: dateOfBirth ? formatToAPI(dateOfBirth) : null,
      availabilityStartDate: availabilityStartDate
        ? formatToAPI(availabilityStartDate)
        : null,
      availabilityEndDate: availabilityEndDate
        ? formatToAPI(availabilityEndDate)
        : null,
      jobId: jobId ? jobId : null,
    }
  }

  const submit = (data: CaregiverUpdate) => {
    mutate({ caregiverId: caregiver.id, data: transformData(data) })
    methods.reset(data)
  }

  const availableJobs = caregiver.availableJobs.map((job) => {
    return { label: job.name, value: job.id }
  })

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit((data) => submit(data))}>
          <Grid
            container
            rowSpacing={{ sm: 4 }}
            columnSpacing={{ md: 3, lg: 6, xl: 12 }}
          >
            <Grid item sm={12} lg={7}>
              <CaregiverPersonalInfo />
              <CaregiverContactInfo />
              <CaregiverLanguages />
            </Grid>
            <Grid item sm={12} lg={5}>
              <CaregiverAdditionalInfo
                availableJobs={availableJobs}
                availableHobbies={availableHobbies}
              />
              <UploadPhotoModal open={isOpen} setOpen={setIsOpen} caregiver={caregiver} />
            </Grid>
          </Grid>
          <Divider sx={{ marginTop: 3 }} />
          <Button
            disabled={!methods.formState.isValid || !methods.formState.isDirty}
            sx={{ marginTop: 1 }}
            type="submit"
            variant="contained"
          >
            Zapisz
          </Button>
        </form>
      </FormProvider>
    </>
  )
}
