import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"

export type ControlledCheckboxProps = Partial<CheckboxProps> & {
  name: string
  label: string
}

const ControlledCheckbox = ({
  name,
  label,
  ...props
}: ControlledCheckboxProps) => {
  const { control } = useFormContext()

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Checkbox
              checked={value}
              onChange={onChange}
              size="small"
              {...props}
            />
          )}
        />
      }
      label={label}
    />
  )
}
export default ControlledCheckbox
