import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Layout from "./common/components/Layout"
import LoginPage from "./features/auth/components/LoginPage"

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { CaregiverDetail } from "./features/caregivers/components/CaregiverDetail"
import { CaregiverListPage } from "./features/caregivers/components/CaregiversListPage"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

import { createTheme, ThemeProvider } from "@mui/material"
import DashboardPage from "./pages/dashboard/DashboardPage"


const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    element: <Layout />,
    path: "/dashboard",
    children: [
      {
        path: "",
        element: <DashboardPage />,
      },
      {
        path: "caregivers/",
        element: <CaregiverListPage />,
      },
      {
        path: "caregivers/:caregiverId/",
        element: <CaregiverDetail />,
      },
    ],
  },
])

const App = () => {
  const theme = createTheme()

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
        <RouterProvider router={router} />
      </LocalizationProvider>
    </ThemeProvider>
  )
}
export default App
