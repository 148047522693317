import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"

export interface Option {
  label: string
  value: string
}

export interface ControlledSelectProps {
  name: string
  label: string
  multiple?: boolean
  options: Option[]
}

const ControlledSelect = ({
  name,
  label,
  multiple = false,
  options,
}: ControlledSelectProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth margin="dense" size="small">
          <InputLabel>{label}</InputLabel>
          <Select
            multiple={multiple}
            fullWidth
            onChange={onChange}
            value={value || null}
            label={label}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  )
}
export default ControlledSelect
