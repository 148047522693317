import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import "dayjs/locale/pl"
import "dayjs/plugin/utc"

dayjs.extend(relativeTime)
dayjs.extend(utc)

export const getParsedISODate = (date: string) => date.split("T")[0]

export const dateToUTC = (inputDate: Date | string | number): Date => {
  const date = new Date(inputDate)
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export const timeFromNow = (date: string): string =>
  dayjs.utc(date).locale("pl").fromNow(true)

export const timeFromNowAgo = (date: string): string =>
  dayjs.utc(date).locale("pl").fromNow()

export const formatToAPI = (date: Date | string): string =>
  dayjs(date).format("YYYY-MM-DD")

export const toDayjs = (date: string) => dayjs(date)
