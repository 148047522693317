import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createCaregiver,
  createCaregiverExperience,
  createCaregiverReference,
  deleteCaregiverExperience,
  deleteCaregiverReferenceMutation,
  fetchCaregiver,
  fetchCaregiverExperiences,
  fetchCaregiverReferences,
  fetchCaregivers,
  fetchTraits,
  updateCaregiver,
  updateCaregiverExperience,
  updateCaregiverPhoto,
  updateCaregiverQuestions,
} from '../api';
import { QuestionAnswerPayload } from '../components/CaregiverQuestionsList';
import { Filters } from '../components/CaregiversListPage';
import { CaregiverCreate } from '../components/FilterBar';
import {
  Caregiver,
  CaregiverDetail,
  CaregiverExperiences,
  CaregiverExperiencesTransformed,
  CaregiverList,
  CaregiverReference,
  CaregiverReferencePayload,
  CaregiverUpdate,
  Experience,
  Trait,
} from '../types/caregiver';

export const useCaregiversListQuery = (filters: Filters) => {
  return useQuery<CaregiverList, Error>({
    queryKey: ['caregiversList', filters],
    queryFn: () => fetchCaregivers(filters),
    keepPreviousData: true,
  });
};

export const useCaregiverDetailQuery = (caregiverId: number) => {
  return useQuery<CaregiverDetail, Error>({
    queryKey: ['caregiverDetail', caregiverId.toString()],
    queryFn: () => fetchCaregiver(caregiverId),
  });
};

export const useCaregiverCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ data }: { data: CaregiverCreate }) => createCaregiver(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['caregiversList'] });
      toast.success('Dodano nową opiekunkę!', { autoClose: 3000 });
    },
  });
};

export const useCaregiverUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ caregiverId, data }: { caregiverId: number; data: CaregiverUpdate }) =>
      updateCaregiver(caregiverId, data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['caregiverDetail', variables.caregiverId.toString()]);
      queryClient.invalidateQueries(['caregiversList']);
      toast.success('Zaktualizowano dane opiekunki!', { autoClose: 3000 });
    },
  });
};

export const useCaregiverUpdatePhotoMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ caregiverId, data }: { caregiverId: number; data: { file: File } }) =>
      updateCaregiverPhoto(caregiverId, data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['caregiverDetail', variables.caregiverId.toString()]);
      toast.success('Zaktualizowano zdjęcie profilowe!', { autoClose: 3000 });
    },
  });
};

export const useCaregiverQuestionUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ caregiverId, data }: { caregiverId: number; data: QuestionAnswerPayload[] }) =>
      updateCaregiverQuestions(caregiverId, data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['caregiverDetail', variables.caregiverId.toString()]);
      toast.success('Zaktualizowano odpowiedzi na pytania!', {
        autoClose: 3000,
      });
    },
  });
};

const transformExperiences = (data: CaregiverExperiences): CaregiverExperiencesTransformed => {
  return {
    availableTraits: data.availableTraits,
    experiences: data.experiences.map((experience) => {
      const diseases: Trait[] = [];
      const activities: Trait[] = [];
      experience.selectedTraits.forEach((traitId) => {
        const selectedTrait = data.availableTraits.find((t) => t.id === traitId);
        if (selectedTrait?.traitType === 'DISEASE') diseases.push(selectedTrait);
        else if (selectedTrait?.traitType === 'ACTIVITY') activities.push(selectedTrait);
      });
      return { ...experience, diseases, activities };
    }),
  };
};

export const useCaregiverExperiencesQuery = (caregiverId: number) => {
  return useQuery<CaregiverExperiences, Error, CaregiverExperiencesTransformed>({
    queryKey: ['caregiverExperiences', caregiverId],
    queryFn: () => fetchCaregiverExperiences(caregiverId),
    select: transformExperiences,
    staleTime: 10000,
  });
};

export const useCaregiverExperiencesDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ caregiverId, experienceId }: { caregiverId: number; experienceId: number }) =>
      deleteCaregiverExperience(caregiverId, experienceId),
    onSuccess: () => {
      queryClient.invalidateQueries('caregiverExperiences');
      toast.success('Usunięto doświadczenie!', { autoClose: 3000 });
    },
  });
};

export const useCaregiverExperiencesUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      caregiverId,
      experienceId,
      data,
    }: {
      caregiverId: number;
      experienceId: number;
      data: Experience;
    }) => updateCaregiverExperience(caregiverId, experienceId, data),
    onSuccess: () => {
      queryClient.invalidateQueries('caregiverExperiences');
      toast.success('Zaktualizowano doświadczenie!', { autoClose: 3000 });
    },
  });
};

export const useCaregiverExperienceCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ caregiverId, data }: { caregiverId: number; data: Experience }) =>
      createCaregiverExperience(caregiverId, data),
    onSuccess: () => {
      queryClient.invalidateQueries('caregiverExperiences');
      toast.success('Dodano nowe doświadczenie!', { autoClose: 3000 });
    },
  });
};

export const useCaregiverReferencesQuery = (caregiverId: number) => {
  return useQuery<CaregiverReference[], Error>({
    queryKey: ['caregiverReferences', caregiverId],
    queryFn: () => fetchCaregiverReferences(caregiverId),
    staleTime: 10000,
  });
};

export const useCaregiverReferenceCreateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ caregiverId, data }: { caregiverId: number; data: CaregiverReferencePayload }) =>
      createCaregiverReference(caregiverId, data),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['caregiverReferences', variables.caregiverId]);
      toast.success('Dodano nową referencję!', { autoClose: 3000 });
    },
  });
};

export const useCaregiverReferenceDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ caregiverId, referenceId }: { caregiverId: number; referenceId: number }) =>
      deleteCaregiverReferenceMutation(caregiverId, referenceId),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['caregiverReferences', variables.caregiverId]);
      toast.success('Usunięto referencję!', { autoClose: 3000 });
    },
  });
};

export const useTraitsListQuery = () => {
  return useQuery({
    queryKey: ['traitsList'],
    queryFn: fetchTraits,
  });
};
