import axios from "axios"
import { refreshAccessToken } from "../features/auth/api"

const authAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
})

authAxios.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      await refreshAccessToken()
      return authAxios(originalRequest)
    }
    return Promise.reject(error)
  }
)

export default authAxios
