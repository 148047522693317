import axios from "axios"
import authAxios from "../../../utils/axios"
import { User } from "../types/user"

export const login = async (data: {
  username: string
  password: string
}): Promise<void> => {
  await authAxios.post("/auth/login/", data)
}

export const logout = async (): Promise<void> => {
  await authAxios.post("/auth/logout/")
}

export const refreshAccessToken = async (): Promise<void> => {
  await authAxios.post("/auth/refresh/")
}

export const getUsersMe = async (): Promise<User> => {
  try {
    const response = await authAxios.get<User>("/users/me/")
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error("Failed!")
    } else {
      throw new Error("Something went wrong!")
    }
  }
}
