import {
  Button,
  ButtonProps,
  Input,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material"
import { Controller, useFormContext } from "react-hook-form"

export type ControlledFileFieldProps = Partial<ButtonProps> & {
  name: string
}

const ControlledFileField = ({ name, ...props }: ControlledFileFieldProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <>
          <Button component="label">
            Wybierz plik
            <input
              onChange={(e) => {
                onChange(e.target.files)
              }}
              hidden
              type="file"
              name={name}
            />
          </Button>
          {value && value.length > 0 ? value[0]?.name : "Nie wybrano pliku"}
        </>
      )}
    />
  )
}
export default ControlledFileField
