import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import {
  timeFromNowAgo,
} from "../../../utils/dates"
import { CaregiverList as CL } from "../types/caregiver"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { Filters } from "./CaregiversListPage"
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"
import { FilterSharp } from "@mui/icons-material"

interface CaregiverListProps {
  caregivers: CL;
  query: Filters
  setQuery: React.Dispatch<React.SetStateAction<Filters>>
}

const StyledTableRow = styled(TableRow)({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-hover": {
    "&:hover": {
      cursor: "pointer",
    },
  },
})

const headColumns = [
  { name: "firstName", label: "Imię", sorting: true },
  { name: "lastName", label: "Nazwisko", sorting: true },
  { name: "age", label: "Wiek", sorting: true },
  { name: "isSmoker", label: "Prawo jazdy", sorting: false },
  { name: "hasDrivingLicense", label: "Osoba paląca", sorting: false },
  { name: "availabilityStartDate", label: "Dostępność od", sorting: true },
  { name: "availabilityEndDate", label: "Dostępność do", sorting: true },
  { name: "createdAt", label: "Dodano", sorting: true },
  { name: "updatedAt", label: "Ostatnia aktualizacja", sorting: true },
]

export const CaregiverList = ({ caregivers, query, setQuery }: CaregiverListProps) => {
  const navigate = useNavigate()

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuery({ ...query, pageSize: parseInt(event.target.value), page: 0 });
  }

  return (
    <TableContainer component={Paper} elevation={0} sx={{ mb: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headColumns.map(column => {
              return (
                <TableCell key={column.name}>
                  {column.sorting ? <TableSortLabel
                    direction={query.order}
                    active={query.orderBy === column.name}
                    onClick={() => {
                      if (query.orderBy !== column.name) {
                        setQuery((filters) => {
                          return { ...filters, order: "desc", orderBy: column.name }
                        });
                      } else {
                        setQuery((filters) => {
                          return { ...filters, order: filters.order === "asc" ? "desc" : "asc" }
                        })
                      }

                    }}
                  >{column.label}</TableSortLabel> : <>{column.label}</>}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {caregivers.data.map((caregiver) => (
            <StyledTableRow
              key={caregiver.id}
              hover
              onClick={() => navigate(`/dashboard/caregivers/${caregiver.id}/`)}
            >
              <TableCell align="center">{caregiver.firstName}</TableCell>
              <TableCell align="center">{caregiver.lastName}</TableCell>
              <TableCell align="center">{caregiver.age || "-"}</TableCell>
              <TableCell align="center">
                {caregiver.hasDrivingLicense ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <CloseIcon fontSize="small" />
                )}
              </TableCell>
              <TableCell align="center">
                {caregiver.isSmoker ? (
                  <CheckIcon fontSize="small" />
                ) : (
                  <CloseIcon fontSize="small" />
                )}
              </TableCell>
              <TableCell align="center">
                {caregiver.availabilityStartDate || "-"}
              </TableCell>
              <TableCell align="center">
                {caregiver.availabilityEndDate || "-"}
              </TableCell>
              <TableCell align="center">
                {timeFromNowAgo(caregiver.createdAt)}
              </TableCell>
              <TableCell align="center">
                {timeFromNowAgo(caregiver.updatedAt)}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        <TableFooter sx={{ left: 0, bottom: 0, zIndex: 10, position: 'sticky', backgroundColor: '#FFFFFF' }}>
          <TableRow>
            <TablePagination
              colSpan={9}
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={caregivers.totalRows}
              rowsPerPage={query.pageSize}
              page={query.page}
              onPageChange={(_, page) => setQuery({ ...query, page: page })}
              onRowsPerPageChange={handlePageSizeChange}
              labelRowsPerPage={"Wierszy na stronę"}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer >
  )
}
